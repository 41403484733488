/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import {
  Outlet,
  useNavigate,
  useLocation,
  Link,
  json,
  useLoaderData,
  LoaderFunctionArgs,
  useRevalidator,
  // redirect,
} from 'react-router-dom';
import { BotaoComTexto } from '@bit/digix.digixui.botao';
import logoEProcon from 'assets/images/e-procon-logo-branco.svg';
import logoDoSistema from 'assets/images/logo.png';
import { Permissoes } from 'enums';
import { useAuth } from 'hooks';
import { parsedLocalStorage } from 'utils';
import { servicoBase } from 'services/servico-base';
import { AreaDeAtendimento } from 'models/areaDeAtendimento/area-de-atendimento';
import classes from './styles.module.scss';

export async function loader({ request }: LoaderFunctionArgs) {
  const { pathname } = new URL(request.url);

  let areaAtendimento = null;
  const lotacao = localStorage.getItem('lotacao');

  try {
    if (lotacao && pathname !== '/app/aplicativos') {
      areaAtendimento = await servicoBase.get<AreaDeAtendimento>(
        `areadeatendimento/unidade/${lotacao}`,
      );
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
  // @ts-ignore

  return json(areaAtendimento?.data?.[0] ?? null);
}

export function AppLayout(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuLateralAberto, setMenuLateralAberto] = useState(false);
  const { deslogar, usuario, podeAcessar } = useAuth();

  const loaderData = useLoaderData() as AreaDeAtendimento;

  const revalidator = useRevalidator();

  useEffect(() => {
    setTimeout(() => {
      revalidator.revalidate();
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoAplicativos = useMemo(() => {
    const { pathname } = location;
    return pathname === '/app/aplicativos';
  }, [location]);

  const memoMenuItems = useMemo(() => {
    const menuItems = [
      {
        id: 'fila-atendimento',
        path: '/app/fila-atendimento',
        nome: 'Fila de Atendimento',
        filtro: 'fila-atendimento',
        permissoes: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
      {
        id: 'consulta',
        path: '',
        nome: 'Consultas',
        filtro: 'consulta-atendimento',
        permissoes: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.CONCILIADOR,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
      },
      {
        id: 'audiencias',
        path: '',
        nome: 'Audiências',
        filtro: 'audiencias',
        permissoes: [
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.CONCILIADOR,
        ],
      },
      {
        id: 'relatorios',
        path: '',
        nome: 'Relatórios',
        filtro: 'relatorios',
        permissoes: [
          Permissoes.GESTOR_DE_FILA,
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
    ];

    const opcoesPermissao = {
      temQualquerPermissao: true,
    };

    return menuItems.filter(menuItem =>
      !menuItem?.permissoes
        ? true
        : podeAcessar(menuItem.permissoes, opcoesPermissao),
    );
  }, [podeAcessar]);

  const memoMenuSubItems = useMemo(() => {
    const menuSubItems = [
      {
        idItem: 'consulta',
        id: 'consulta-atendimento',
        path: '/app/consulta-atendimento',
        nome: 'Atendimentos',
        icone: 'bullhorn',
        filtro: 'consulta-atendimento',
      },
      {
        idItem: 'consulta',
        id: 'consulta-audiencia',
        path: '/app/consulta-audiencia',
        nome: 'Audiências',
        icone: 'calendar',
        filtro: 'consulta-audiencia',
        permissoes: [
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
      },
      {
        idItem: 'consulta',
        id: 'fornecedores',
        path: '/app/fornecedores',
        nome: 'Fornecedores',
        icone: 'industry',
        filtro: 'fornecedores',
        permissoes: [
          Permissoes.ATENDENTE,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
          Permissoes.CONCILIADOR,
          Permissoes.GESTOR_DE_AUDIENCIA,
        ],
      },
      {
        idItem: 'audiencias',
        id: 'agenda-geral',
        path: '/app/agenda-geral',
        nome: 'Agenda Geral',
        icone: 'calendar',
        filtro: 'agenda-geral',
        dataTestId: 'botao-agenda-geral',
        permissoes: [
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
      {
        idItem: 'audiencias',
        id: 'minhas-audiencias',
        path: '/app/minhas-audiencias',
        icone: 'calendar-alt',
        dataTestId: 'botao-minhas-audiencias',
        nome: 'Minhas audiências',
        filtro: 'minhas-audiencias',
        permissoes: [Permissoes.CONCILIADOR],
      },
      {
        idItem: 'relatorios',
        id: 'classificacao-de-atendimentos',
        path: '/app/relatorios/classificacao-de-atendimentos',
        nome: 'Classificação de Atendimentos',
        icone: 'file-lines',
        filtro: 'classificacao-de-atendimentos',
        dataTestId: 'botao-classificacao-de-atendimentos',
        permissoes: [
          Permissoes.GESTOR_DE_FILA,
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
    ];

    const opcoesPermissao = {
      temQualquerPermissao: true,
    };

    return menuSubItems.filter(menuSubItem =>
      !menuSubItem?.permissoes
        ? true
        : podeAcessar(menuSubItem.permissoes, opcoesPermissao),
    );
  }, [podeAcessar]);

  const memoSubMenuItemsSecundario = useMemo(() => {
    const subMenuItemsSecundario = [
      {
        id: 'configuracoes-do-sistema',
        path: '/app/configuracao',
        nome: 'Configurações do sistema',
        filtro: 'configuracoes-do-sistema',
        dataTestId: 'botao-configuracoes-do-sistema',
        permissoes: [
          Permissoes.GESTOR_DE_AUDIENCIA,
          Permissoes.GESTOR_DE_FILA,
          Permissoes.ADMINISTRADOR_ATENDIMENTO,
        ],
      },
    ];

    return subMenuItemsSecundario.filter(menuItemSecundario =>
      !menuItemSecundario?.permissoes
        ? false
        : podeAcessar(menuItemSecundario.permissoes, {
            temQualquerPermissao: true,
          }),
    );
  }, [podeAcessar]);

  const memoLogo: string = useMemo(() => {
    const logoInterna = memoAplicativos ? logoEProcon : logoDoSistema;

    return logoInterna;
  }, [memoAplicativos]);

  const memoEsconderNavegacao = useMemo(() => {
    return !!location.pathname.includes('aplicativos');
  }, [location.pathname]);

  const getMenuAtivo = useCallback(
    (menuItem: { id: string; filtro: string }) => {
      return (
        memoMenuSubItems
          .filter(item => item.idItem === menuItem.id)
          .some(item => item.path === location.pathname) ||
        location.pathname.includes(menuItem.filtro)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );

  const handleNavigate = useCallback(
    (path: string) => () => {
      parsedLocalStorage.removeAllExceptUserData();
      navigate(path);
    },
    [navigate],
  );

  useLayoutEffect(() => {
    window.addEventListener('scroll', () => {
      setMenuLateralAberto(false);
    });
  }, [menuLateralAberto]);

  const salvarPontoDeAtendimento = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.target;
    parsedLocalStorage.setItem('pontoAtendimentoEscolhido', value);
  };

  const ehConciliador = podeAcessar([Permissoes.CONCILIADOR], {
    temQualquerPermissao: true,
  });

  if (!usuario) {
    return <Outlet />;
  }

  return (
    <>
      <header className="cabecalho-sistema">
        <div className="cabecalho-sistema__container-primario">
          {!memoAplicativos && (
            <button
              type="button"
              onClick={() => {
                setMenuLateralAberto(!menuLateralAberto);
              }}
              className={clsx('botao-hamburguer', classes.botaoHamburguer, {
                'botao-hamburguer_estado-minimizar': menuLateralAberto,
              })}
              aria-label="Menu principal"
            >
              <span className="botao-hamburguer__linha-1" />
              <span className="botao-hamburguer__linha-2" />
              <span className="botao-hamburguer__linha-3" />
            </button>
          )}

          <Link
            to={`/app/${memoAplicativos ? 'aplicativos' : 'fila-atendimento'}`}
            className={clsx({ [classes.paginaAplicativos]: memoAplicativos })}
          >
            <img
              className="cabecalho-sistema__logo"
              width="33"
              src={memoLogo}
              alt="Logotipo do Atendimento"
            />
          </Link>

          {!memoEsconderNavegacao && (
            <nav
              className="cabecalho-sistema__menu"
              aria-label="Menu principal"
            >
              <ul
                className={clsx(
                  'cabecalho-sistema__lista-de-itens',
                  classes.menuLista,
                )}
              >
                {memoMenuItems.map(menuItem => (
                  <li key={menuItem.id}>
                    {menuItem.path === '' ? (
                      <span className="dropdown">
                        <button
                          type="button"
                          className={clsx('cabecalho-sistema__item-de-menu', {
                            'cabecalho-sistema__item-de-menu_ativo':
                              getMenuAtivo(menuItem),
                          })}
                          aria-label=""
                          aria-haspopup="true"
                        >
                          <div role="presentation">{menuItem.nome}</div>
                          {!menuItem.path && (
                            <i className="far fa-angle-down u-margem-esquerda-pequena" />
                          )}
                        </button>
                        <ul
                          className="dropdown__lista"
                          aria-label="Submenu"
                          key={`ul-${menuItem.id}`}
                        >
                          {memoMenuSubItems.map(
                            subMenuItem =>
                              subMenuItem.idItem === menuItem.id && (
                                <li
                                  className="dropdown__item"
                                  key={subMenuItem.id}
                                >
                                  <Link
                                    className="dropdown__link"
                                    to={subMenuItem.path}
                                  >
                                    <i
                                      className={`far fa-${subMenuItem.icone} dropdown__icone`}
                                    />
                                    {subMenuItem.nome}
                                  </Link>
                                </li>
                              ),
                          )}
                        </ul>
                      </span>
                    ) : (
                      <span>
                        <button
                          type="button"
                          className={clsx('cabecalho-sistema__item-de-menu', {
                            'cabecalho-sistema__item-de-menu_ativo':
                              getMenuAtivo(menuItem),
                          })}
                          aria-label=""
                          aria-haspopup="true"
                          onClick={handleNavigate(menuItem.path)}
                        >
                          <div role="presentation">{menuItem.nome}</div>
                        </button>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>

        <div className="cabecalho-sistema__container-secundario">
          {loaderData?.pontos_de_atendimento &&
          loaderData?.pontos_de_atendimento?.length > 0 &&
          loaderData?.chamada_ativada &&
          ehConciliador ? (
            <div
              className={clsx(
                'cabecalho-sistema__lista-de-itens u-margem-inferior-pequena',
                classes.divSelect,
              )}
            >
              <label>Ponto: </label>
              <select
                className={clsx(
                  'formulario__select',
                  classes.selectSemPreenchimento,
                )}
                defaultValue={
                  parsedLocalStorage.getItem<string>(
                    'pontoAtendimentoEscolhido',
                  ) || ''
                }
                onChange={e => salvarPontoDeAtendimento(e)}
              >
                <option className={classes.opcaoSelect} key={0} value="">
                  Selecione...
                </option>
                {loaderData?.pontos_de_atendimento?.map(opcao => (
                  <option
                    key={opcao.id}
                    value={opcao.id}
                    className={classes.opcaoSelect}
                  >
                    {opcao.nome}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <nav className="cabecalho-sistema__menu" aria-label="Menu secundário">
            <ul className="cabecalho-sistema__lista-de-itens">
              <li className="dropdown dropdown_direita">
                <button
                  type="button"
                  className="cabecalho-sistema__item-de-menu"
                  aria-label="Opções para o usuário Administrador"
                  aria-haspopup="true"
                >
                  <span className="cabecalho-sistema__icone-do-usuario">
                    <i className="far fa-user" />
                  </span>
                  <span className="cabecalho-sistema__nome-do-usuario">
                    {usuario?.name}
                  </span>
                  <i className="far fa-angle-down u-margem-esquerda-pequena" />
                </button>
                <ul
                  className={clsx('dropdown__lista', classes.submenu)}
                  aria-label="Submenu"
                >
                  {memoSubMenuItemsSecundario.map(subMenuItem => (
                    <li className="dropdown__item" key={subMenuItem.id}>
                      <BotaoComTexto
                        classesAuxiliares="dropdown__link"
                        visual="sem-preenchimento"
                        cor="cinza"
                        onClick={handleNavigate(subMenuItem.path)}
                      >
                        <i className="fa-regular fa-cog dropdown__icone" />
                        {subMenuItem.nome}
                      </BotaoComTexto>
                    </li>
                  ))}
                  <li className="dropdown__item">
                    <a
                      className="dropdown__link"
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={`https://termos.digix.com.br/politicasDePrivacidade?produto=${process.env.REACT_APP_PRIVACY_POLICY_ENV}`}
                    >
                      <i className="fa-regular fa-lock dropdown__icone" />
                      Politica de privacidade
                    </a>
                  </li>
                  <li className="dropdown__item">
                    <a
                      className="dropdown__link"
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={`https://termos.digix.com.br/termos/visualizacao?produto=${process.env.REACT_APP_PRIVACY_POLICY_ENV}`}
                    >
                      <i className="fa-regular fa-lock dropdown__icone" />
                      Termos
                    </a>
                  </li>
                  <li className="dropdown__item">
                    <BotaoComTexto
                      classesAuxiliares="dropdown__link"
                      visual="sem-preenchimento"
                      cor="cinza"
                      onClick={() => navigate('/app/aplicativos')}
                      habilitado={location.pathname !== '/app/aplicativos'}
                    >
                      <i className="fa-regular fa-computer-classic dropdown__icone" />
                      Aplicativos
                    </BotaoComTexto>
                  </li>
                  <li className="dropdown__item">
                    <BotaoComTexto
                      classesAuxiliares="dropdown__link"
                      visual="sem-preenchimento"
                      cor="cinza"
                      onClick={deslogar}
                    >
                      <i className="far fa-sign-out dropdown__icone" />
                      Sair
                    </BotaoComTexto>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <nav
        className={clsx('menu-lateral menu-lateral_fixo', classes.menuLateral, {
          'menu-lateral_minimizado': !menuLateralAberto,
        })}
      >
        <ul className="menu-lateral__lista">
          {memoMenuItems.map(menuItem => (
            <li
              className={clsx('menu-lateral__item', {
                dropdown: memoMenuSubItems.some(
                  submenu => submenu.idItem === menuItem.id,
                ),
              })}
              key={`menu-li-${menuItem.id}`}
            >
              {menuItem.path ? (
                <Link
                  to={menuItem.path}
                  type="button"
                  className={clsx('menu-lateral__link', {
                    'menu-lateral__link_ativo': getMenuAtivo(menuItem),
                  })}
                >
                  <span className="menu-lateral__texto u-padding-esquerda-mini">
                    {menuItem.nome}
                    {!menuItem.path && (
                      <i className="far fa-angle-down u-margem-esquerda-pequena" />
                    )}
                  </span>
                </Link>
              ) : (
                <div
                  className={clsx('menu-lateral__link', {
                    'menu-lateral__link_ativo': getMenuAtivo(menuItem),
                  })}
                  style={{ cursor: 'pointer' }}
                >
                  <span className="menu-lateral__texto u-padding-esquerda-mini">
                    {menuItem.nome}
                    {!menuItem.path && (
                      <i className="far fa-angle-down u-margem-esquerda-pequena" />
                    )}
                  </span>
                </div>
              )}

              {memoMenuSubItems.some(
                submenu => submenu.idItem === menuItem.id,
              ) && (
                <ul
                  className="dropdown__lista"
                  aria-label="Submenu"
                  key={`ul-${menuItem.id}`}
                  style={{ minWidth: 'calc(100% - 32px)', left: '8px' }}
                >
                  {memoMenuSubItems.map(
                    subMenuItem =>
                      subMenuItem.idItem === menuItem.id && (
                        <li className="dropdown__item" key={subMenuItem.id}>
                          <Link
                            className="dropdown__link"
                            to={subMenuItem.path}
                          >
                            <i
                              className={`far fa-${subMenuItem.icone} dropdown__icone`}
                            />
                            {subMenuItem.nome}
                          </Link>
                        </li>
                      ),
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <Outlet />
    </>
  );
}
